@use "../core/mixins";

.error-page {
  @include mixins.flexBox(center, center);
  flex-direction: column;
  gap: 40px;

  &__text {
    @include mixins.flexBox(center);
    flex-direction: column;
    gap: 10px;
  }
}
