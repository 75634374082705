@use '../../../../assets/styles/core/vars';
@use '../../../../assets/styles/core/mixins';

.about {
  background: rgba(vars.$gray, 0.23);
  padding: 100px 0;

  &__text {
    font-size: 20px;
    line-height: 30px;
    font-weight: 600;
    margin-top: 30px;
  }

  &__actions {
    @include mixins.flexBox(center, space-between);
    margin-top: 56px;
  }

  &__logos {
    @include mixins.flexBox(center);
    gap: 80px;
  }

  &__logo {
    height: 101px;

    &-img {
      @include mixins.fullDimensions;
      object-fit: contain;
    }
  }
}

.team {
  margin-top: 48px;

  &__cards {
    @include mixins.flexBox(center, space-between);
    flex-wrap: wrap;
    gap: 32px;
    margin: 24px 0 48px;
  }

  &__card {
    flex: 0 0 263px;
    height: 300px;
    position: relative;
    overflow: hidden;

    &::before {
      content: '';
      position: absolute;
      inset: 0;
      background: linear-gradient(
        360deg,
        rgba(0, 0, 0, 0.8) 0%,
        rgba(0, 0, 0, 0) 30%
      );
      z-index: 1;
    }

    &-img,
    &-figcaption {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 0.5s;
    }

    &-figcaption {
      @include mixins.flexBox(flex-start, flex-start);
      flex-direction: column;
      gap: 2px;
      color: vars.$white;
      background: transparent;
      padding: 16px;
      z-index: 2;
      top: calc(100% - 84px);
    }

    &:hover &-figcaption {
      top: 0;
      background: rgba(vars.$black, 0.8);
    }

    &-name,
    &-position {
      color: vars.$white;
    }

    &-name {
      font-size: 20px;
      line-height: 30px;
      font-weight: 500;
    }

    &-position,
    &-description {
      font-size: 14px;
      line-height: 21px;
      font-weight: 400;
    }

    &-description {
      padding-top: 10px;
      margin-top: auto;
    }
  }
}

// Media
@media screen and (max-width: 991px) {
  .team {
    &__cards {
      flex-wrap: wrap;
      justify-content: center;
      margin-bottom: 40px;
    }

    &__card {
      flex: 0 1 45%;
      min-width: 340px;
      height: 450px;
    }
  }
}

@media screen and (max-width: 767px) {
  .about {
    padding: 80px 0 50px;

    &__actions {
      flex-direction: column;
      gap: 40px;
      margin-top: 35px;
    }

    &__logos {
      align-self: stretch;
      justify-content: space-evenly;
      gap: 0;
    }

    &__logo {
      flex: 0 0 100px;
    }
  }

  .team__card {
    @include mixins.flexBox(stretch);
    flex-direction: column;
    flex: 0 1 450px;
    height: auto;
    min-width: auto;

    &::before {
      display: none;
    }

    &-img,
    &-figcaption {
      position: relative;
    }

    &-img {
      height: 450px;
    }

    &-figcaption {
      padding: 20px 0 0;
      background: transparent !important;
    }

    &-figcaption,
    &-figcaption .main-text {
      height: auto;
      color: vars.$dark;
    }

    &-position {
      margin-bottom: 15px;
    }

    &-description {
      padding-top: 0;
    }
  }
}

@media screen and (max-width: 575px) {
  .about {
    padding-top: 60px;

    &__logos {
      justify-content: space-around;
      flex-direction: column;
      gap: 40px;
    }

    &__logo {
      flex-basis: 80px;
    }
  }
}
