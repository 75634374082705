@use "../../../../assets/styles/core/vars";
@use "../../../../assets/styles/core/mixins";

.profit {
  .main-title {
    text-align: center;
  }

  &__cards {
    @include mixins.flexBox(center, center);
    gap: 21px;
    margin-top: 64px;
  }

  &__card {
    padding-top: 40px;
    flex: 1 1 369px;
    min-height: 595px;
    background: url(../../../../assets/images/profit/card-bg.png) center / 100% 100%
      no-repeat;

    &-title {
      text-align: center;
      font-size: 32px;
      line-height: 48px;
      font-weight: 600;
      margin-bottom: 24px;
      padding: 0 30px;
    }

    &-figure {
      margin-bottom: 24px;
      padding: 0 50px;
      width: 100%;
    }

    &-img {
      @include mixins.fullDimensions;
      object-fit: contain;
    }

    &-text {
      text-align: center;
      padding: 0 30px;
    }
  }
}

// Media
@media screen and (max-width: 991px) {
  .profit {
    &__cards {
      flex-wrap: wrap;
      gap: 40px;
      align-items: stretch;
    }

    &__card {
      flex: 0 1 45%;
      padding-bottom: 90px;
      min-height: auto;

      &-figure {
        max-width: 200px;
        padding: 0;
        margin: 0 auto 24px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .profit {
    &__cards {
      gap: 10px;
    }

    &__card {
      flex-basis: 70%;

      &-figure {
        max-width: 170px;
      }

      &-text {
        padding: 0 60px;
      }
    }
  }
}

@media screen and (max-width: 575px) {
  .profit__card {
    flex-basis: 100%;

    &-title {
      font-size: 25px;
      line-height: 36px;
    }
  }
}
