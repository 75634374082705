// Common
$containerWidth: 1148px;

$baseFont: "Montserrat", sans-serif;
$lexendMegaFont: "LexendMega", sans-serif;
$lexendFont: "Lexend", sans-serif;

// Colors
$white: #fff;
$black: #000;
$dark: #1e1e1e;
$skyblue: #00a5ce;
$darkerBlue: #038cae;
$darkGray: #494949;
$gray: #d5d5d5;

// Font sizes & weights
$baseFontSize: 16px;
