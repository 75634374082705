@use "../../assets/styles/core/vars";
@use "../../assets/styles/core/mixins";

.header {
  position: fixed;
  inset: 0 0 auto;
  z-index: 10;
  border-bottom: 1px solid vars.$white;
  transition: 0.5s;

  &.fixed {
    &::after {
      content: "";
      position: absolute;
      inset: 0;
      background: rgba(vars.$black, 0.4);
      backdrop-filter: blur(15px);
      z-index: -1;
    }
  }

  &__body {
    @include mixins.flexBox(center, space-between);
  }

  .main-btn {
    padding: 13px 21px;
    margin-left: 16px;
    transition: 0.3s;
  }

  .logo {
    color: vars.$white;
  }

  &.header-dark {
    border-color: vars.$black;

    .logo {
      color: vars.$black;
    }

    .nav__item-link,
    .nav__item:last-child .nav__item-link {
      color: vars.$black;
      border-color: vars.$black;
    }

    .nav__burger span {
      background: vars.$dark;
    }
  }

  &.header-dark.fixed {
    border-color: vars.$white;

    .logo {
      color: vars.$white;
    }

    .nav__item-link,
    .nav__item:last-child .nav__item-link {
      color: vars.$white;
      border-color: vars.$white;
    }

    .nav__burger span {
      background: vars.$white;
    }

    .main-btn.outlined-dark {
      color: vars.$white;
      border: 1px solid rgba(vars.$white, 0.17);
      background: rgba(vars.$white, 0.05);
    }
  }
}

.nav {
  @include mixins.flexBox(center);
  margin-left: auto;

  &__menu {
    @include mixins.flexBox(center);
  }

  &__item-link {
    color: vars.$white;
    font-family: vars.$lexendMegaFont;
    font-size: vars.$baseFontSize;
    font-weight: 600;
    padding: 23px 21px;
    border-left: 1px solid vars.$white;
    transition: 0.5s;
  }

  &__item:last-child &__item-link {
    border-right: 1px solid vars.$white;
  }

  &__burger {
    width: 35px;
    height: 17px;
    position: relative;
    display: none;
    transition: 0.5s;

    span {
      width: 100%;
      height: 2px;
      background: vars.$white;
      position: absolute;
      right: 0;
      transform: translateY(-50%);
      border-radius: 5px;
      transition: 0.5s;
    }

    span:nth-child(1) {
      top: 0;
    }

    span:nth-child(2) {
      top: 50%;
      width: 70%;
      right: 0;
    }

    span:nth-child(3) {
      top: 100%;
      width: 50%;
    }
  }
}

// Media
@media screen and (max-width: 991px) {
  .header {
    padding: 14px 0;
    border-bottom: 1px solid rgba(vars.$white, 0.5);

    .login__btn {
      margin-left: auto;
    }

    .nav {
      order: 1;
      margin-left: 30px;

      &__menu {
        position: fixed;
        top: 100%;
        left: 0;
        z-index: 30;
        width: 100vw;
        height: 100%;
        background: rgba(vars.$skyblue, 0.5);
        backdrop-filter: blur(15px);
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 25px;
        transition: 0.7s;
      }

      &__item-link {
        border: none !important;
        font-size: 25px;
        padding: 5px;
      }

      &__burger {
        display: block;
        z-index: 31;
        width: 30px;
      }
    }

    .nav.menu-open {
      .nav__menu {
        top: 0;
      }

      .nav__burger {
        height: 30px;

        span:is(:nth-child(1), :nth-child(3)) {
          top: 50%;
          width: 100%;
        }

        span:nth-child(2) {
          transform: translateX(20px);
          opacity: 0;
        }

        span:nth-child(1) {
          transform: translateY(-50%) rotate(45deg);
        }

        span:nth-child(3) {
          transform: translateY(-50%) rotate(-45deg);
        }
      }
    }
  }
}

@media screen and (max-width: 575px) {
  .header {
    .main-btn {
      padding: 10px 20px;
    }
  }
}
