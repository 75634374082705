@use "../core/vars";
@use "../core/mixins";

.insight {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
    height: 644px;
    background: vars.$dark;
  }

  &__subtitle {
    color: vars.$white;
    max-width: 460px;
    margin-top: 24px;
  }

  &__figure {
    height: 524px;
    margin-top: 80px;
  }

  &__figure-img {
    @include mixins.fullDimensions;
    object-fit: cover;
  }

  &__article {
    @include mixins.flexBox(false, space-between);
    gap: 50px;
    padding-top: 64px;
  }

  &__content {
    color: vars.$darkGray;
    flex: 0 1 779px;

    > * + * {
      margin-top: 32px;
    }

    strong {
      font-size: 20px;
      line-height: 30px;
      font-weight: 600;
    }

    p + h2 {
      margin-top: 48px;
    }

    h2 {
      font-family: vars.$lexendFont;
      font-size: 38px;
      line-height: 53px;
      font-weight: 500;

      span {
        color: vars.$skyblue;
      }
    }

    h3 {
      font-family: vars.$lexendFont;
      font-size: 24px;
      line-height: 33px;
      font-weight: 500;
      color: vars.$dark;
    }

    figure {
      height: 434px;
    }

    img {
      @include mixins.fullDimensions;
      object-fit: cover;
    }

    blockquote {
      font-size: 20px;
      line-height: 30px;
      position: relative;
      padding-left: 27px;
    }

    blockquote::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 4px;
      background: vars.$skyblue;
    }

    hr {
      height: 1px;
      background: rgba(vars.$black, 0.1);
    }
  }

  .article__socials {
    @include mixins.flexBox(center, space-between);

    &-title {
      font-family: vars.$lexendMegaFont;
      font-size: 16px;
      line-height: normal;
      font-weight: 800;
      color: vars.$dark;
    }

    &-links {
      @include mixins.flexBox(center);
      gap: 16px;
      margin: 0;
    }

    &-link {
      margin: 0;
    }
  }

  &__aside {
    flex: 0 1 270px;
    align-self: flex-start;
    position: sticky;
    top: 100px;

    &-links {
      margin-top: 24px;
    }

    &-link {
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
      color: vars.$dark;
    }

    &-link + &-link {
      margin-top: 24px;
    }
  }

  & + .insights {
    padding-top: 0;
  }
}

// Media
@media screen and (max-width: 991px) {
  .insight {
    &__figure {
      margin-top: 40px;
    }

    &__article {
      flex-direction: column;
      padding-top: 45px;
      gap: 30px;
    }

    &__aside {
      flex: initial;
      position: relative;
      top: unset;
    }

    &__aside-links {
      @include mixins.flexBox(flex-start, false, inline-flex);
      flex-direction: column;
      gap: 15px;
    }

    &__aside-link + &__aside-link {
      margin: 0;
    }
  }
}

@media screen and (max-width: 767px) {
  .insight {
    &__subtitle {
      margin-top: 15px;
    }

    &__figure {
      height: 400px;
    }
  }

  .insight__content {
    strong {
      font-size: 18px;
      line-height: 28px;
    }

    h2 {
      font-size: 32px;
      line-height: 41px;
    }

    p + h2 {
      margin-top: 30px;
    }

    > * + * {
      margin-top: 20px;
    }

    figure {
      height: 370px;
    }
  }
}
