@use "../core/vars";
@use "../core/mixins";

.insights-hero {
  padding-top: 80px;

  .page-title {
    color: vars.$dark;
  }

  &__cards {
    display: grid;
    grid-template: repeat(2, 236px) / repeat(2, 1fr);
    grid-gap: 20px;
    margin-top: 58px;

    .insights__card.main-card {
      flex-direction: column;
      align-items: initial;
      grid-row: 1 / -1;

      .insights__card-figure {
        flex: 0 1 330px;
      }

      .insights__card-info {
        padding: 32px 20px 31px;
      }
    }

    .insights__card {
      @include mixins.flexBox();
      background: rgba(vars.$gray, 0.23);

      &-figure {
        margin: 0;
        flex: 0 1 236px;
      }

      &-info {
        flex: 1;
        padding: 36px 20px 35px;
      }

      &-link {
        @include mixins.flexBox(center, false, inline-flex);
        gap: 8px;
        padding-top: 24px;
        font-family: vars.$lexendMegaFont;
        font-size: vars.$baseFontSize;
        font-weight: 800;
        color: vars.$skyblue;
      }

      &-link svg {
        width: 18px;
        height: 15px;
      }

      &-link svg path {
        fill: currentColor;
      }
    }
  }
}

.insights-page .insights .container {
  margin: 0;
  width: 100%;
}

.select-box .form-input {
  border: none;
  width: auto;
  padding: 0;
}

// Media
@media screen and (max-width: 991px) {
  .insights-hero {
    padding-top: 50px;

    &__cards {
      grid-template-columns: 80%;
      justify-content: center;
    }
  }
}

@media screen and (max-width: 767px) {
  .insights-hero__cards {
    grid-template-columns: 1fr;
    justify-content: center;
    margin-top: 30px;
  }
}

@media screen and (max-width: 575px) {
  .insights-hero__cards .insights__card {
    flex-direction: column;
  }
}
