.thank-you-page-container {
  .main-title {
    text-align: center;
  }
}

@media (min-width: 992px) {
  .thank-you-page-container {
    display: flex;
    align-items: center;
    min-height: calc(100vh - 141px);
  }
}