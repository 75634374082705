@use "./vars";
@use "./mixins";

html {
  box-sizing: border-box;
  scroll-behavior: smooth;
  min-height: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  padding: 0;
  margin: 0;
}

body {
  font-family: vars.$baseFont;
  font-size: vars.$baseFontSize;
  min-height: 100lvh;

  &.static {
    overflow: hidden;
  }
}

#root {
  @include mixins.flexBox();
  flex-direction: column;
  min-height: inherit;
}

.main {
  flex: 1;

  &[class$="-page"] {
    section:first-child {
      padding: 146px 0 100px;
    }
  }
}

a {
  display: inline-block;
  text-decoration: none;
  color: inherit;
}

button {
  border: none;
  background: transparent;
  cursor: pointer;
}

li {
  list-style: none;
}

img {
  vertical-align: middle;
}

section {
  padding-top: 175px;
}

.container {
  width: min(vars.$containerWidth, 100% - 30px);
  margin: 0 auto;
}

.containerless {
  max-width: 1440px;
  margin: 0 auto;
}

.logo {
  width: 90px;
  height: 26px;

  svg {
    @include mixins.fullDimensions;
  }
}

.main-btn {
  font-family: vars.$lexendMegaFont;
  font-size: vars.$baseFontSize;
  font-weight: 800;
  text-align: center;
  padding: 17px 26px;
  color: vars.$white;
  border-radius: 50px;
  background: vars.$skyblue;
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);

  &.outlined-light {
    border: 1px solid rgba(vars.$white, 0.17);
    background: rgba(vars.$white, 0.05);
  }

  &.outlined-dark {
    color: vars.$black;
    border: 1px solid rgba(vars.$black, 0.17);
    background: rgba(vars.$black, 0.05);
  }
}

.colored-text {
  color: vars.$skyblue;
}

.page-title {
  font-family: vars.$lexendFont;
  font-size: 72px;
  line-height: normal;
  font-weight: 800;
  color: vars.$white;
  text-align: left;
}

.main-title {
  font-family: vars.$lexendFont;
  font-size: 38px;
  line-height: 53px;
  font-weight: 400;
  color: vars.$dark;
}

.main-subtitle {
  font-family: vars.$baseFont;
  font-size: 24px;
  line-height: 33px;
  font-weight: 500;
  color: vars.$dark;
}

.main-text {
  font-family: vars.$baseFont;
  font-size: vars.$baseFontSize;
  line-height: 24px;
  font-weight: 400;
  color: vars.$darkGray;
}

.select-box {
  @include mixins.flexBox(center);
  gap: 7px;
  color: vars.$dark;
  font-family: vars.$lexendMegaFont;
  font-size: 16px;
  font-weight: 800;
  line-height: normal;
  padding: 17px 26px;
  border-radius: 50px;
  border: 2px solid rgba(vars.$black, 0.1);
  cursor: pointer;
}

// Media

@media screen and (max-width: 1199px) {
  .containerless {
    max-width: calc(100% - 30px);
  }
}

@media screen and (max-width: 1024px) {
  .container {
    width: calc(100% - 30px);
  }
}

@media screen and (max-width: 991px) {
  .main[class$="-page"] section:first-child {
    padding-bottom: 60px;
  }

  .logo {
    height: auto;
    width: 110px;
  }

  section {
    padding-top: 100px;
  }

  .page-title {
    font-size: 60px;
  }

  .main-title {
    text-align: center;
  }

  .main-btn {
    font-size: 15px;
    font-weight: 600;
    padding: 15px 22px;
  }
}

@media screen and (max-width: 767px) {
  .main[class$="-page"] section:first-child {
    padding-top: 120px;
  }

  section {
    padding-top: 80px;
  }

  .page-title {
    font-size: 50px;
  }
}

@media screen and (max-width: 575px) {
  section {
    padding-top: 60px;
  }

  .page-title {
    font-size: 40px;
    line-height: 51px;
  }

  .main-title {
    font-size: 30px;
    line-height: 45px;
  }

  .main-subtitle {
    font-size: 20px;
    line-height: 30px;
  }

  .main-text {
    font-size: 14px;
    line-height: 22px;
  }
}
