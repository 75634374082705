@use "../../../../assets/styles/core/vars";
@use "../../../../assets/styles/core/mixins";

.solutions {
  padding-left: min(9vw, 146px);

  &__header,
  &__footer {
    padding-right: 9vw;
  }

  &__header {
    margin-bottom: 64px;
  }

  &__title {
    font-family: vars.$baseFont;
    font-size: 32px;
    line-height: 48px;
    font-weight: 600;
  }

  &__content {
    @include mixins.flexBox(center, space-between);
    gap: 49px;
  }

  &__figure {
    flex: 0 0 50%;
    max-width: 100%;
  }

  &__img {
    @include mixins.fullDimensions;
    object-fit: cover;
  }

  &__footer {
    margin-top: 64px;

    .main-text {
      font-size: 20px;
      line-height: 30px;
      font-weight: 600;
      margin-bottom: 32px;
    }
  }
}

.accordion__item {
  padding: 16px 0;
  border-bottom: 1px solid rgba(vars.$dark, 0.19);

  &-title {
    @include mixins.flexBox(center, space-between);
    font-family: vars.$lexendFont;
    font-weight: 400;
    cursor: pointer;
    user-select: none;
    transition: 0.5s;

    .cross {
      width: 24px;
      height: 24px;
      position: relative;
      margin-right: 12px;
    }

    .cross span {
      width: 100%;
      height: 1px;
      background: vars.$black;
      position: absolute;
      top: calc(50% - 0.5px);
      left: calc(50% - 0.5px);
      transition: 0.5s;
    }

    .cross span:nth-child(2) {
      transform: rotate(-90deg);
    }
  }

  &-text {
    overflow: hidden;
    transition: 0.5s;
  }

  &-list {
    padding: 0 30px 0 23px;
  }

  &-list--item {
    list-style: outside disc;
  }

  &.active {
    .accordion__item-title {
      color: vars.$skyblue;
    }

    .accordion__item-text {
      margin-top: 16px;
    }

    .cross span {
      background: vars.$skyblue;
    }

    .cross span:nth-child(2) {
      transform: rotate(0deg);
    }
  }
}

// Media
@media screen and (max-width: 1199px) {
  .solutions {
    padding-left: 0;

    &__header,
    &__footer {
      padding-right: 0;
    }
  }
}

@media screen and (max-width: 991px) {
  .solutions {
    &__header {
      margin-bottom: 35px;
    }

    &__title {
      font-size: 28px;
      line-height: 44px;
    }

    &__figure {
      display: none;
    }

    &__footer {
      text-align: center;
    }
  }

  .accordion {
    flex-basis: 80%;
    margin: 0 auto;
  }
}

@media screen and (max-width: 767px) {
  .solutions__title {
    font-size: 24px;
    line-height: 40px;
  }

  .accordion {
    flex-basis: 100%;

    &__item-list {
      padding: 0 0 0 15px;
    }
  }
}

@media screen and (max-width: 575px) {
  .solutions {
    &__title {
      font-size: 20px;
      line-height: 34px;
    }

    &__footer .main-text {
      font-size: 16px;
      line-height: 25px;
    }
  }
}
