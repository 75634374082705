@use "../../../../assets/styles/core/vars";
@use "../../../../assets/styles/core/mixins";

.efficiency {
  background: vars.$dark;
  margin-top: -190px;
  padding: 270px 0 110px;

  &__body {
    text-align: center;
  }

  &__blocks {
    @include mixins.flexBox(flex-start, center);
  }

  &__block {
    max-width: 369px;
    padding-top: 10px;
    min-height: 163px;

    & + & {
      padding-left: 34px;
      margin-left: 34px;
      border-left: 1px solid rgba(vars.$white, 0.3);
    }

    .main-subtitle,
    .main-text {
      color: vars.$white;
    }

    .main-subtitle {
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 24px;
    }
  }

  &__cta {
    margin-top: 65px;
  }

  &__links {
    @include mixins.flexBox(center, center);
    gap: 50px;
    margin-top: 49px;
    color: vars.$white;
  }

  &__link {
    text-decoration: underline;
  }
}

// Media
@media screen and (max-width: 991px) {
  .efficiency {
    &__blocks {
      flex-wrap: wrap;
      gap: 40px;
    }

    &__block + &__block {
      margin: 0;
      padding: 0;
      border: none;
    }

    &__block {
      max-width: 100%;
      flex: 0 1 45%;
      min-height: auto;
      padding: 0;
    }
  }
}

@media screen and (max-width: 767px) {
  .efficiency {
    padding: 255px 0 70px;

    &__block {
      flex-basis: 70%;
    }
  }
}

@media screen and (max-width: 575px) {
  .efficiency {
    &__block {
      flex-basis: 100%;
    }

    &__links {
      flex-direction: column;
      gap: 30px;
    }
  }
}
