@use "../core/vars";
@use "../core/mixins";

.contact {
  padding-top: 186px 0 120px !important;
  color: vars.$dark;

  &__body {
    @include mixins.flexBox(center, space-between);
    gap: 50px;
  }

  &__info {
    flex-basis: 584px;
  }

  &__subtitle {
    line-height: 36px;
    margin-top: 24px;
    font-weight: 600;
  }

  &__list-title {
    font-size: 16px;
    line-height: 24px;
    margin: 40px 0 24px;
  }

  &__list-item {
    list-style: inside decimal-leading-zero;
  }

  &__list-item + &__list-item {
    margin-top: 10px;
  }

  & + .enlist {
    margin-top: 1px;
  }

  .select-box {
    border: none;
    padding: 0;

    [class$="-control"] {
      padding: 17px 26px;
      border: 2px solid rgba(vars.$black, 0.1);
      border-radius: inherit;
    }
  }
}

.form {
  @include mixins.flexBox();
  gap: 16px;
  flex-direction: column;
  background: rgba(vars.$gray, 0.23);
  padding: 44px 32px;
  flex: 0 1 487px;
  position: relative;

  &-label {
    position: relative;
    transition: 0.3s;

    &:has(.form-input--error.visible) {
      padding-bottom: 25px;
    }
  }

  &-input {
    width: 100%;
    font-family: vars.$lexendMegaFont;
    font-size: 16px;
    font-weight: 800;
    color: vars.$dark;
    padding: 17px 26px;
    border-radius: 50px;
    border: 2px solid rgba(vars.$black, 0.1);
    background: vars.$white;
    position: relative;
    z-index: 1;

    &::placeholder {
      color: inherit;
    }

    &--error {
      display: inline-block;
      font-size: 14px;
      font-weight: 500;
      color: crimson;
      transition: 0.3s;
      position: absolute;
      bottom: 5px;
      left: 28px;
      z-index: -1;
      opacity: 0;

      &.visible {
        opacity: 1;
      }
    }
  }

  &-submit {
    align-self: flex-start;
    margin-top: 10px;
    transition: 0.3s;

    &:disabled {
      filter: grayscale(0.2) opacity(0.5);
      pointer-events: none;
      user-select: none;
    }
  }

  &-alert {
    @include mixins.flexBox(center, center);
    position: absolute;
    inset: 0;
    z-index: 5;
    padding: 40px;
    font-size: 22px;
    font-weight: 500;
    text-align: center;
    color: vars.$skyblue;
    transition: 0.3s;
    opacity: 0;
    visibility: hidden;

    &::after {
      content: "";
      position: absolute;
      inset: 0;
      z-index: -1;
      background: rgba(vars.$white, 0.5);
      backdrop-filter: blur(15px);
    }

    &.show {
      opacity: 1;
      visibility: visible;
    }
  }
}

// Media
@media screen and (max-width: 991px) {
  .contact {
    &__body {
      flex-direction: column;
      gap: 30px;
    }

    &__info {
      flex-basis: auto;
    }

    .form {
      flex: unset;
      width: 70%;
    }
  }
}

@media screen and (max-width: 767px) {
  .contact {
    &__subtitle {
      margin-top: 15px;
    }

    &__list-title {
      margin: 30px 0 18px;
    }
  }

  .form {
    width: 90%;
    padding: 40px 20px;

    &-submit {
      align-self: stretch;
    }
  }
}

@media screen and (max-width: 575px) {
  .contact .form {
    width: 100%;
  }
}
