@use "../../assets/styles/core/vars";
@use "../../assets/styles/core/mixins";

.enlist {
  margin-top: 175px;
  padding: 53px 0 40px;
  background: url(../../assets/images/enlist/enlist-img.jpg) center / cover
    no-repeat;

  .container {
    width: min(1346px, 100% - 30px);
  }

  &__title {
    line-height: 59px;
    color: vars.$white;
    text-align: center;
    max-width: 1148px;
    margin: 0 auto;
  }
}

.brands {
  margin: 28px auto 0;
  padding: 0 40px;

  .swiper-wrapper {
    transition-timing-function: linear;
  }

  &__item {
    height: 163px;
    border-left: 1px solid rgba(vars.$white, 0.3);
    padding: 0 35px;

    &-img {
      @include mixins.fullDimensions;
      object-fit: contain;
      user-select: none;
    }
  }
}

// Media
@media screen and (max-width: 1024px) {
  .enlist {
    padding-right: 0;
    padding-left: 0;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      inset: 0;
      background: rgba(vars.$dark, 0.4);
    }

    &__body {
      position: relative;
    }
  }
}

@media screen and (max-width: 991px) {
  .enlist {
    margin-top: 100px;
    padding: 70px 0;
  }
}

@media screen and (max-width: 767px) {
  .enlist {
    margin-top: 80px;

    .main-title {
      font-size: 28px;
      line-height: 40px;
    }
  }
}

@media screen and (max-width: 575px) {
  .enlist {
    .main-title {
      font-size: 25px;
      line-height: 37px;
    }
  }

  .brands__item {
    padding: 0 20px;
    height: 110px;
  }
}
