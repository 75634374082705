@use "../../../../assets/styles/core/vars";
@use "../../../../assets/styles/core/mixins";

.hero {
  @include mixins.flexBox();
  height: min(900px, 100vh);
  position: relative;
  padding-top: 85px;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    z-index: 1;
    background: rgba(vars.$black, 0.36);
  }

  &__poster,
  &__bg-video {
    @include mixins.fullDimensions;
    object-fit: cover;
    position: absolute;
    inset: 0;
    z-index: 0;
  }

  &__body {
    @include mixins.flexBox(center);
    height: 100%;
  }

  &__info {
    position: relative;
    z-index: 3;
  }

  .main-subtitle {
    color: vars.$white;
    margin-top: 14px;
    max-width: 437px;
    font-weight: 500;
  }

  .main-text {
    margin-top: 16px;
    color: vars.$white;
    max-width: 622px;
  }

  &__cta {
    margin-top: 33px;
    border-width: 2px;
    box-shadow: 5px 5px 30px 0px rgba(vars.$black, 0.45);
  }
}

// Media
@media screen and (max-width: 991px) {
  .hero {
    height: 100vh;

    &::before {
      background: rgba(vars.$black, 0.5)
        url(../../../../assets/images/hero/hero-bg.jpg) center / cover no-repeat;
      background-blend-mode: color;
    }

    &__bg-video {
      display: none;
    }

    .main-subtitle {
      font-size: 21px;
      line-height: 30px;
    }
  }
}

@media screen and (max-width: 767px) {
  .hero {
    .main-subtitle {
      font-size: 19px;
      line-height: 28px;
    }

    &__cta {
      margin-top: 50px;
    }
  }
}

@media screen and (max-width: 575px) {
  .hero {
    padding-bottom: 70px;

    &__body {
      padding-top: 60px;
    }

    .main-subtitle {
      font-size: 17px;
      line-height: 26px;
    }
  }
}
