@mixin importFont($name, $weightName, $weight) {
  @font-face {
    src: url("../../fonts/#{$name}/#{$name}-#{$weightName}.ttf")
      format("truetype");
    font-family: $name;
    font-weight: $weight;
    font-display: swap;
  }
}

@include importFont("Lexend", "Light", 300);
@include importFont("Lexend", "Regular", 400);
@include importFont("Lexend", "ExtraBold", 800);

@include importFont("LexendMega", "SemiBold", 600);
@include importFont("LexendMega", "ExtraBold", 800);

@include importFont("Montserrat", "Regular", 400);
@include importFont("Montserrat", "Medium", 500);
@include importFont("Montserrat", "SemiBold", 600);
