@use "../../../../assets/styles/core/vars";
@use "../../../../assets/styles/core/mixins";

.guideline {
  position: relative;

  &__body {
    @include mixins.flexBox(center);
    gap: 34px;
  }

  &__figure {
    flex: 0 1 50%;
  }

  &__img {
    @include mixins.fullDimensions;
    object-fit: cover;
  }

  &__info {
    flex: 0 1 540px;
    padding-left: 129px;
  }

  &__list {
    margin: 21px 0 32px;
    counter-reset: guideline;

    &-item {
      position: relative;
    }

    &-item::before {
      content: "0" counter(guideline);
      counter-increment: guideline;
      font-family: vars.$lexendMegaFont;
      font-size: 64px;
      font-weight: 800;
      line-height: 100px;
      color: rgba(vars.$dark, 0.06);
      position: absolute;
      top: 0;
      left: -129px;
    }

    &-item + &-item {
      margin-top: 32px;
    }

    &-item--title {
      margin-bottom: 13px;
    }
  }
}

// Media
@media screen and (max-width: 1199px) {
  .guideline {
    &__body {
      align-items: stretch;
    }

    &__figure {
      flex-basis: 45%;
    }

    &__img {
      object-position: 65%;
    }
  }
}

@media screen and (max-width: 991px) {
  .guideline {
    &__figure {
      display: none;
    }

    &__info {
      flex-basis: 100%;
      padding: 0;
      text-align: center;
    }

    &__list {
      @include mixins.flexBox(flex-start);
      flex-wrap: wrap;
      gap: 30px;
      margin-bottom: 60px;
    }

    &__list-item {
      @include mixins.flexBox(center);
      flex-direction: column;
      flex-basis: 400px;
      margin: 0 auto !important;
    }

    &__list-item::before {
      position: static;
    }
  }
}

@media screen and (max-width: 767px) {
  .guideline {
    &__list-item {
      flex-shrink: 1;
    }
  }
}
