@mixin flexBox($aItems: false, $jContent: false, $dFlex: flex) {
  display: $dFlex;

  @if $aItems {
    align-items: $aItems;
  }
  @if $jContent {
    justify-content: $jContent;
  }
}

@mixin fullDimensions {
  width: 100%;
  height: 100%;
}
