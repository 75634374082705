@use "../../assets/styles/core/vars";
@use "../../assets/styles/core/mixins";

@keyframes fade {
  from {
    transform: translateY(20px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.insights-page__cta {
  border: 2px solid rgba(vars.$dark, 0.17);
  display: block;
  width: fit-content;
  margin: 0 auto;
}

.insights {
  padding: 100px 0 67px;

  &__caption {
    @include mixins.flexBox(center, space-between);
    margin-bottom: 28px;
  }

  &__cards {
    @include mixins.flexBox(flex-start, center);
    flex-wrap: wrap;
    gap: 21px;
  }

  &__card {
    flex: 1 1 30%;
    min-width: 270px;

    &:not(.insights-hero__cards .insights__card) {
      animation: fade 0.7s ease-out;
    }

    &-figure {
      height: 236px;
      position: relative;
      margin-bottom: 21px;
    }

    &-figcaption {
      position: absolute;
      bottom: -31px;
      right: 26px;
    }

    &-btn {
      @include mixins.flexBox(center, center);
      padding: 15px;
      height: 62px;
      width: 62px;
    }

    &-btn svg {
      width: 25px;
    }

    &-imglink,
    &-img {
      @include mixins.fullDimensions;
      object-fit: cover;
    }

    &-title {
      margin-bottom: 11px;
    }
  }
}

// Media
@media screen and (max-width: 991px) {
  .insights {
    &__cards {
      gap: 40px;
    }

    &__card {
      flex: 0 70%;

      &-figure:not(.insights-hero .insights__card-figure) {
        height: 310px;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .insights {
    padding: 80px 0 50px;

    &__card {
      flex: 1 1 100%;
    }

    &__card-figcaption {
      bottom: -25px;
    }

    &__card-btn {
      width: 50px;
      height: 50px;
    }
  }
}

@media screen and (max-width: 575px) {
  .insights {
    padding-top: 60px;

    &__caption {
      flex-direction: column;
      gap: 20px;
    }
  }
}
