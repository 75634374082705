@use "../../assets/styles/core/vars";
@use "../../assets/styles/core/mixins";

.footer {
  border-top: 1px solid vars.$dark;

  &__body {
    @include mixins.flexBox(center, space-between);
  }

  .logo {
    color: vars.$dark;
  }

  .nav__item-link {
    border-color: vars.$dark;
    color: vars.$dark;
  }
}

.copy {
  background: vars.$dark;
  padding: 26px 0 24px;

  &__body {
    @include mixins.flexBox(center, space-between);
  }

  &__text {
    color: vars.$white;
  }

  .social {
    @include mixins.flexBox(center);
    gap: 31px;

    &__link {
      width: 18px;
      height: 18px;
    }

    &__link svg {
      @include mixins.fullDimensions;
      object-fit: contain;
    }
  }
}

// Media
@media screen and (max-width: 767px) {
  .footer {
    padding-top: 40px;

    &__body,
    .nav__menu {
      flex-direction: column;
    }

    &__body {
      gap: 20px;
      padding-bottom: 20px;
    }

    .logo {
      width: 130px;
      height: auto;
    }

    .nav {
      margin: 0;
    }

    .nav__item-link {
      border: none;
      padding: 15px;
      font-size: 18px;
    }
  }

  .copy__body {
    flex-direction: column;
    gap: 30px;
  }

  .copy__text {
    order: 1;
  }
}
